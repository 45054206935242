/*css varable generator + fallback*/
@mixin var($property, $varName) {
  #{$property}: map-get($vars, $varName);
  #{$property}: var(--#{$varName});
}

/*placeholder color mixin*/
@mixin palceholders($placeholder-color) {
  &::-webkit-input-placeholder {
    color: $placeholder-color !important;
  }

  &:-moz-placeholder {
    color: $placeholder-color !important;
  }

  &::-moz-placeholder {
    color: $placeholder-color !important;
  }

  &:-ms-input-placeholder {
    color: $placeholder-color !important;
  }
}
