@import "../_scss-vars/vars";
@import "../_scss-vars/mixins";
@import "../_scss-vars/typo";

.carousel-title {
  margin: 0 0 5px 0;
  height: 80px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  padding: 0 19px;
  width: 100%;
  @include calc-font-size(320, 960, 18, 24);

  @media screen and (min-width: #{map_get($user-breakpoints, medium)}) {
    @include calc-font-size(960, 1200, 24, 30);
    margin: 0 0 calc(20 / 16) + em 0;
    height: 80px;
  }

  @media screen and (min-width: #{map_get($user-breakpoints, large)}) {
    @include calc-font-size(1200, 1920, 30, 48);
    margin: 0 0 15px 0;

  }

}

.banner-carousel-title {
  margin: 0;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  @include var(color, theme-gray);
  color: #000000;
  cursor: pointer;
  text-wrap: normal;

  @supports (not ((-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px)))) {
    @include var(color, theme-black);
  }

  @media screen and (min-width: #{map_get($user-breakpoints, medium)}) {
    padding: 0 calc(20 / 16) + em;
    font-size: 35px;
  }
}

// slider styles
.slide-item {
  overflow: hidden;
  position: relative;
  border: 2px solid;
  // @todo uncommented again =) Checked contact page and found that it doesnt look good when the blocks maintain their ratio
  // height: 300px;

  margin-bottom: 23px;

  @include var(color, theme-black);
  @include var(border-color, theme-box-gray);

  &.keep-ratio {
    //height: 230px;
  }

  &:hover {
    color: white;
    text-decoration: none;
  }

  @media screen and (min-width: #{map_get($user-breakpoints, small)}) {
    height: auto;
    margin: auto;

    &.keep-ratio {
      height: 230px;
    }

    &:after {
      padding-bottom: 0;
      height: 19.5vw;
      max-height: 300px;
    }
  }

  @media screen and (min-width: #{map_get($user-breakpoints, large)}) {
    &:after {
      max-height: 340px;
    }


  }

  &:hover {
    .slider--item-descr .slider-banner-item--descr {
      // color: #000000;
    }
  }

  .item-container {
    //position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
  }

  a {
    @include var(color, theme-black);
  }
}

.slide-banner-item {
  height: 100%;
  width: 100%;
  position: relative;
  @include var(color, theme-white);

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.slider--item-descr {
  text-align: left;
  position: relative;

  .slider-banner-item--title {
    font-size: calc(32 / 16) + em;
    color: #ffffff;
    font-weight: bold;
  }

  .slider-banner-item--descr {
    font-size: 2em;
    font-weight: bold;
    transition: color 0.2s ease;
    color: #ffffff;

    @media screen and (min-width: #{map_get($user-breakpoints, large)}) {
      font-size: calc(40 / 16) + em;
    }
  }

  @media screen and (min-width: #{map_get($user-breakpoints, small)}) {
    .slider-banner-item--title {
      font-size: 1em;
    }

    .slider-banner-item--descr {
      font-size: 1.5em;
    }

    @include calc-font-size(768, 1200, 24, 30);
  }

  @media screen and (min-width: #{map_get($user-breakpoints, large)}) {
    @include calc-font-size(1200, 1920, 30, 48);
  }
}

.slider--item-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;

  img {
    width: 100%;
    height: 100%;
  }

  .thumbnail-video,
  img {
    //filter: blur(10px);
    cursor: pointer;
    width: 100%;
    height: 100%;
    object-fit: cover;
    //transition: all 0.5s ease;

    &.video-load {
      //transition: all 0.5s ease;
      //filter: blur(10px);
    }
  }
}

.swiper-navs-container {
  width: 100%;
  left: 0;
  position: relative;
  z-index: 9999999999;
  //display: none;
  text-align: center;
  top: 0;
  @include calc-font-size(320, 960, 18, 24);

  h3 {
    display: none;
  }

  @media screen and (min-width: #{map_get($user-breakpoints, medium)}) {
    display: block;
    @include calc-font-size(960, 1920, 24, 30);
  }

}

.swiper-navigation-prev,
.swiper-navigation-next {
  @include var(color, theme-gray);
  font-family: swiper-icons;
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
  line-height: 1.2;
  top: 0;
  position: absolute;
  cursor: pointer;
  transition: color 0.2s ease;
  height: 80px;
  display: flex;
  align-items: center;

  &:hover {
    color: white;
  }

  @supports (not ((-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px)))) {
    @include var(color, theme-black);
  }

  &.swiper-button-disabled {
    opacity: 0.2;
  }

}

.swiper-navigation-prev {
  left: 0px;

  &:after {
    content: "prev";
  }
}

.swiper-navigation-next {
  right: 0px;

  &:after {
    content: "next";
  }
}

.mobile-swiper-nav {
  color: #ffffff;
  width: 100%;
  text-align: center;
  height: 3em;
  align-items: center;

  .swiper-navigation-prev,
  .swiper-navigation-next {
    color: #ffffff;
    font-size: 1em;
    top: 0;
    line-height: 1;
    position: relative;
    margin-top: 5px;
  }

  h3 {
    margin-bottom: 0;
    width: 100%;
    font-weight: bold;
    line-height: 1;
    font-size: 1.3em;
  }
}

.scroll-down:before {
  position: absolute;
  top: calc(50% - 6px);
  left: calc(50% - 4px);
  transform: rotate(-45deg);
  display: block;
  width: 8px;
  height: 8px;
  content: "";
  border: 2px solid white;
  border-width: 0px 0 2px 2px;
}

@keyframes bounce {

  0%,
  100%,
  20%,
  50%,
  80% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-5px);
  }

  60% {
    transform: translateY(-2px);
  }
}

// scroll-btn
.scroll-down {
  position: absolute;
  top: -50px;
  left: 50%;
  margin-left: -13px;
  display: block;
  width: 26px;
  height: 26px;
  border: 2px solid;
  @include var(border-color, theme-white);
  background-size: 10px auto;
  border-radius: 50%;
  animation: bounce 2s infinite 2s;
  transition: all 0.2s ease-in;
  opacity: 1;
  z-index: 99999;
  pointer-events: all;

  &.show-scroll-hint {
    opacity: 1;
  }

  //small - 768px
  //medium - 960px
  //large = 1200px
  @media screen and (min-width: #{map_get($user-breakpoints, medium)}) {

    // uncomment for disable scroll button on desktop

    // display: none;
  }
}
