/*font family fonts*/
/*add: .font-face(pf_beausans_probbook, pfbeausanspro_book);*/

// @include font-face(Samplino, fonts/Samplino);
//@include font-face("Samplina Neue", fonts/SamplinaNeue, bold, italic);
//@include font-face(Samplinoff, fonts/Samplinoff, null, null, woff);
//@include font-face(Samplinal, fonts/Samplinal, 500, normal, eot woff2 woff);
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}
@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff ttf svg) {
  $src: null;
  $extmods: (
          eot: "?",
          svg: "#" + str-replace($name, " ", "_")
  );
  $formats: (
          otf: "opentype",
          ttf: "truetype"
  );
  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }
  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    font-display: swap;
    src: $src;
  }
}

/*...dots*/
/*block must have size*/
@mixin ellipsis{
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
}
@mixin text_shadows($txt_shadow){
  -webkit-text-shadow: $txt_shadow;
  -moz-text-shadow:$txt_shadow;
  text-shadow: $txt_shadow;
}
@mixin multicolumns($count:2, $gap:3em){
  -moz-column-count: $count;
  -moz-column-gap: $gap;
  -webkit-column-count:$count;
  -webkit-column-gap: $gap;
  -o-column-count: $count;
  -o-column-gap: $gap;
  -ms-column-count: $count;
  -ms-column-gap: $gap;
  column-count: $count;
  column-gap: $gap;
}
@mixin word-wrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@mixin calc-font-size($min-vw, $max-vw, $min-font-size, $max-font-size) {
  font-size: calc(#{$min-font-size}px + (#{$max-font-size} - #{$min-font-size}) * ((100vw - #{$min-vw}px) / (#{$max-vw} - #{$min-vw})));

  @if $max-vw==1920 {
    @media screen and (min-width:1921px) {
      font-size: #{$max-font-size}px;
    }
  }

  @if $min-vw==300 {
    font-size: #{$min-font-size}px;
  }
}
