@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro-Light.eot');
  src: url('../fonts/MyriadPro-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MyriadPro-Light.woff2') format('woff2'),
  url('../fonts/MyriadPro-Light.woff') format('woff'),
  url('../fonts/MyriadPro-Light.ttf') format('truetype'),
  url('../fonts/MyriadPro-Light.svg#MyriadPro-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro-Regular.eot');
  src: url('../fonts/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MyriadPro-Regular.woff2') format('woff2'),
  url('../fonts/MyriadPro-Regular.woff') format('woff'),
  url('../fonts/MyriadPro-Regular.ttf') format('truetype'),
  url('../fonts/MyriadPro-Regular.svg#MyriadPro-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro-Semibold.eot');
  src: url('../fonts/MyriadPro-Semibold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MyriadPro-Semibold.woff2') format('woff2'),
  url('../fonts/MyriadPro-Semibold.woff') format('woff'),
  url('../fonts/MyriadPro-Semibold.ttf') format('truetype'),
  url('../fonts/MyriadPro-Semibold.svg#MyriadPro-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('../fonts/MyriadPro-Bold.eot');
  src: url('../fonts/MyriadPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MyriadPro-Bold.woff2') format('woff2'),
  url('../fonts/MyriadPro-Bold.woff') format('woff'),
  url('../fonts/MyriadPro-Bold.ttf') format('truetype'),
  url('../fonts/MyriadPro-Bold.svg#MyriadPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nasalization Rg';
  src: url('../fonts/NasalizationRg-Regular.eot');
  src: url('../fonts/NasalizationRg-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/NasalizationRg-Regular.woff2') format('woff2'),
      url('../fonts/NasalizationRg-Regular.woff') format('woff'),
      url('../fonts/NasalizationRg-Regular.ttf') format('truetype'),
      url('../fonts/NasalizationRg-Regular.svg#NasalizationRg-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

