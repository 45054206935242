
::-webkit-scrollbar-button {
  display: none;
  height: 13px;
  border-radius: 0px;
  background-color: #F0F4F4;

  &:hover {
    background-color: #F0F4F4;
  }
}

::-webkit-scrollbar-thumb {
  background-color: #A3B4B9;
  border-radius: 16px;

  &:hover {
    background-color: #6d8890;
    //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
}

::-webkit-scrollbar-track {
  background-color: #F0F4F4;
  transition: all .3s ease;
  border-radius: 5px;

  &:hover {
    background-color: #e4eaeb;
  }
}

::-webkit-scrollbar {
  width: 8px;
}
