/*Global vars*/
$theme-black:#1a1a1a;
$theme-white: #ffffff;
$theme-box-gray: #d3d3d3;
$theme-gray: #575757;

// mixin with root variables (used for native css)
@mixin cssVars() {
  :root {
    --theme-black: #1a1a1a;
    --theme-white: #ffffff;
    --theme-box-gray: #d3d3d3;
    --theme-gray: #575757;
  }
}

// variables list used for legacy support
$vars: (
  theme-black: #1a1a1a,
  theme-white: #ffffff,
  theme-box-gray: #d3d3d3,
  theme-gray: #575757
);
// regured copy for coloscheme generator
$colors: (
  theme-black: #1a1a1a,
  theme-white: #ffffff,
  theme-box-gray: #d3d3d3,
  theme-gray: #575757
);

/*columns*/
$column_spacer: 15px;
$column_spacer-left: $column_spacer;
$column_spacer-right: $column_spacer;
$column_counter: 12;
$cont_width: 100%;
$max_cont_width: 1170px;

$globalPaddingLeft: 60px;
$globalBigLeft: 75px;

/*grid sizes*/
//you can use in media queries specyfic breakpoint
//@media screen and (min-width: #{map_get($user-breakpoints, extra-small)}){}
$user-breakpoints: (
  small: 768px,
  medium: 960px,
  large: 1200px,
);
