/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "swiper/swiper-bundle.css";

@import "assets/_scss-vars/vars";
@import "assets/_scss-vars/mixins";
@import "assets/_scss-vars/animate";
//@import "assets/_scss-vars/box";
//@import "assets/_scss-vars/grid";
//@import "assets/_scss-vars/typo";
@import "assets/_scss-vars/bars";
@import "assets/_scss-vars/flex";
@import "assets/_scss-vars/fonts";
@import "assets/_scss-vars/typography";
@import "assets/_scss-vars/normalize";
@import "~@fortawesome/fontawesome-free/css/all.css";

@include cssVars();

* {
  box-sizing: border-box;
}

//html,
//body {
//  min-height: 100%;
//  height: 100%;
//}

html {
  height: 100%;
  overflow: auto;
}

body {
  height: 100%;
}

body {
  font-size: 16px;
  line-height: calc(20 / 16);
  -webkit-overflow-scrolling: touch;
  font-family: "Myriad Pro";
  @include var(color, theme-black);
  scroll-behavior: smooth;
}

p {
  font-size: 19px;
}

.page-grid {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 20px auto 1fr auto 20px;
  grid-template-rows: auto 1fr auto;

  @media screen and (min-width: #{map_get($user-breakpoints, large)}) {
    grid-template-columns: 60px auto 1fr auto 60px;
  }
}

.page-body {
  // css grid used on contact page
  grid-row: 1/4;
  grid-column: 1/6;
  z-index: 1;
  position: absolute;
  top: 100%;
  width: 100%;
  transition: transform 3s cubic-bezier(.09, .42, 0, .98);
  background-color: rgb(255 255 255 / 20%);

  /* support backdrop-filter */
  @supports ((-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px))) {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }

  /* no support backdrop-filter */
  @supports (not ((-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px)))) {
    // box-shadow:0 0 10px 5px rgba(0, 0, 0, 0.2), inset 0 0 50px 0px rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.6);
  }

}

.banner-closed {
  transform: translateY(0);
}

.banner-small-initial {
  //transform: translateY(-50px);
  transform: translateY(-80px);

  transition: transform 2s 0s cubic-bezier(.09, .42, 0, .98);

  //@media screen and (min-width: #{map_get($user-breakpoints, medium)}) {
  //  transform: translateY(-80px);
  //}
}

.banner-small {
  //transform: translateY(-50px);
  transform: translateY(-80px);

  transition: transform .8s cubic-bezier(.09, .42, 0, .98);

  //@media screen and (min-width: #{map_get($user-breakpoints, medium)}) {
  //  transform: translateY(-80px);
  //}
}

.banner-large {
  transform: translateY(-100%);
  transition: transform .8s cubic-bezier(.09, .42, 0, .98);

}

.banner-small,
.banner-large {
  @media screen and (max-height: 400px) {
    .blurred {
      padding-bottom: 5px !important;
    }

    .slide-item {
      &:after {
        display: none;
      }

      .item-container {
        position: relative;
      }
    }

    .slide-banner-item {
      padding: 15px !important;
    }

    .slider--item-descr {
      .slider-banner-item--title {
        font-size: 1.5em;
      }

      .slider-banner-item--descr {
        margin-bottom: 0;
        font-size: 2em;
      }
    }

    .mobile-swiper-nav {
      height: 2em;
      margin-top: 10px !important;
    }

    .carousel-title {
      @include animate;
    }
  }
}

.banner-large {
  @media screen and (max-height: 400px) {
    .carousel-title {
      height: 50px;
      @include animate;
    }
  }
}

app-header {
  // css grid used on contact page
  grid-row: 1 / 2;
  grid-column: 2 / 5;

  width: 100%;
  overflow: hidden;
  z-index: 99; //should be changed to 99 when we need header at th top of the page
  top: 0;
  left: 0;
}

.video-banner {
  height: 100%;
  background: lightgray;
  position: relative;
  overflow: hidden;
}

.show-on-hover {
  bottom: 0;
  transform: translate3d(0, calc(100% - 75px), 0);
  transition: transform 0.3s ease-in-out, opacity 0.2s ease;
  width: 100%;

  &:hover {
    transform: translate3d(0, 0, 0);

    .scroll-down {
      opacity: 0;
    }

    .swiper-navs-container {
      display: block;
    }
  }
}

@import "assets/scss/cases";

.plyr--video,
.plyr__video-wrapper {
  height: 100%;
}

.plyr--video {
  video {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    background: #2f2f2f;
  }
}

.video-wrapper {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //z-index: -1;
  //pointer-events: none;
  overflow: hidden;
  background: black;

  iframe {
    width: 100vw;
    height: 56.25vw;
    /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh;
    /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

app-video-banner-item {
  display: block;
  height: 100%;
}

.main-page-container {
  position: relative;
  overflow: hidden;
  height: 100%;

  &.overflow-hidden {
    app-header {
      z-index: 3;
    }
  }
}

.swiper-mobile {

  @media all and (min-width: 768px) {
    display: none;
  }
}

.swiper-desktop {
  display: none;

  @media all and (min-width: 768px) {
    display: block;
  }
}

.cases-slider {
  .swiper-slide {
    align-items: center;
    font-size: 40px;
    padding: 0 25px 25px;
    display: flex;
  }
}

.swiper-slide {
  .slide-banner-item {
    .slider--item-video {
      display: none;
    }
  }
}

//.swiper-slide-active,
//.swiper-slide-next,
//.swiper-slide-prev {
//  .slide-banner-item {
//    .slider--item-video {
//      display: block;
//    }
//  }
//}

.banner-large {
  .swiper-slide-active {
    .slide-banner-item {
      .slider--item-video {
        display: block;
      }
    }
  }
}

@media all and (min-width: 768px) {
  .banner-large {
    .swiper-slide-active {
      .slide-banner-item {
        .slider--item-video {
          display: none;
        }
      }
    }
  }

  .swiper-slide {
    .slide-banner-item {
      .slider--item-video {
        //opacity: 0;
        display: none;
      }

      &:hover {
        .slider--item-video {
          //opacity: 1;
          display: block;
        }
      }
    }
  }
}

img {
  max-width: 100%;
}

app-video-slider,
app-case {
  .swiper-container {
    height: 100%;
  }
}


::cue {
  background: none;
  text-shadow: 1px 1px 2px rgb(0, 0, 0);
  font-family: "Myriad Pro", "Verdana", "HelveticaNeue", "Helvetica Neue",
  sans-serif;
  font-weight: bold;
}

.grecaptcha-badge {
  //z-index: 99999;
  display: none;
}
